// import Tap from '@tapfiliate/tapfiliate-js';

export const tapLead = ({ userId, InquireID }) => {
  // try {
  //   Tap.lead(userId, { meta_data: { inquireId: InquireID } });
  // } catch (error) {
  //   console.log('🚀 ~ file: tracking.js ~ line 8 ~ tapLead ~ error', error);
  // }
};

export const tapConversion = ({ orderId, amount, identifier }) => {
  // try {
  //   if (identifier) {
  //     Tap.conversion(orderId, amount, identifier);
  //   } else {
  //     Tap.conversion(orderId, amount);
  //   }
  // } catch (error) {
  //   console.log('🚀 ~ file: tracking.js ~ line 15 ~ tapConversion ~ error', error);
  // }
};

export const tapCustomer = ({ userId }) => {
  // try {
  //   Tap.customer(userId);
  // } catch (error) {
  //   console.log('🚀 ~ file: tracking.js ~ line 23 ~ trackUserSignUp ~ error', error);
  // }
};

export const initConversionTracking = ({ contentCategory, content, value }) => {
  if (window.fbq) {
    window.fbq('track', 'InitiateCheckout', {
      content_category: contentCategory,
      value,
      contents: [content],
    });
    console.log('fbq');
  }
};

export const purchaseTracking = ({ orderId, amount, items = [] }) => {
  // tapConversion({ orderId, amount });
  if (window.fbq) {
    window.fbq('track', 'Purchase', {
      content_ids: [orderId],
      value: amount,
      currency: 'USD',
    });
    console.log('fbq purchase');
  }
};
