/*
  *payLoading
  bill_yang 2021.08
*/
import React, { Component } from 'react';
import { Spin } from 'antd';
// import { clearLocalStorage } from '../../untils/auth';

export default class Loading extends Component {
  render() {
    return (
      <div className="loading-pay">
        <Spin size="large" />
      </div>
    );
  }
}
