import React from 'react';
import { Helmet } from 'react-helmet-async';
import metaData from './data.json';

const defaultMeta = {
  title: 'Airtaxi - Book Private Jet Flights | Pet Friendly Shared Seats - Airacer',
  description:
    'Need to charter a private jet? Airacer offers the most affordable air taxi and private jet charter flights online. Visit our website today to learn more!',
  keywords: 'air taxi, airtaxi, charter flight, charter flights, private jet',
};

const HeaderMeta = ({ type, children }) => {
  return (
    <Helmet>
      <title>{metaData[type].title || defaultMeta.title}</title>
      <meta name="description" content={metaData[type].description || defaultMeta.description} />
      <meta name="keywords" content={metaData[type].keywords || defaultMeta.keywords} />
      {children}
    </Helmet>
  );
};

export default HeaderMeta;
