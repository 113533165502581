/*
 *主路由
 */
import React from 'react';

// 旧首页 之后删除
// const NewHome = React.lazy(() => import('../pages/newHome/NewHome'));
// 首页
const Home = React.lazy(() => import('../pages/home/Home'));

// 经销商注册页面
const AffiliatePartner = React.lazy(() => import('pages/affiliatePartner'));

// 获取报价
const CharterQuote = React.lazy(() => import('../pages/charterQuote/CharterQuote'));
// blog列表页面
const Blog = React.lazy(() => import('../pages/blog/Blog'));
// blog详情页面
const BlogDetails = React.lazy(() => import('../pages/blog/BlogDetails'));
// 定期航班
const SearchFlights = React.lazy(() => import('../pages/searchFlights/SearchFlights'));
// 定期航班详情
const SearchDetails = React.lazy(() => import('../pages/searchFlights/SearchDetails'));
// 空腿列表
const EmptyLeg = React.lazy(() => import('../pages/emptyLeg/EmptyLeg'));
// 空腿详情
const EmptyLegDetails = React.lazy(() => import('../pages/emptyLeg/EmptyLegDetails'));

// 贩卖飞机页面
const JetSale = React.lazy(() => import('../pages/jetSale'));
// 贩卖飞机详情页面

const JetSaleDetails = React.lazy(() => import('../pages/jetSale/JetSaleDetail'));

// 搜索结果页
// const Results = React.lazy(() => import('../pages/results/Results'));
// 结算页
const PayDetails = React.lazy(() => import('../pages/results/PayDetails'));
// 定期航班结算页
const SearchPayDetails = React.lazy(() => import('../pages/results/SearchPayDetails'));
// 订单列表
const FlightsList = React.lazy(() => import('../pages/flights/FlightsList'));
// 订单详情
const FlightsDetails = React.lazy(() => import('../pages/flights/FlightsDetails'));
// 定期航班订单详情
const ScheduledDetails = React.lazy(() => import('../pages/flights/ScheduledDetails'));
// 宠物航班订单详情
const PetDetails = React.lazy(() => import('../pages/flights/PetDetails/index'));
// 订单选择乘客
const FlightsPassenger = React.lazy(() => import('../pages/flights/FlightsPassenger'));
// 修改 添加乘客
const SearchFlightsPassengers = React.lazy(() =>
  import('../pages/flights/SearchFlightsPassengers')
);
// 包机列表
const InquireList = React.lazy(() => import('../pages/inquire/InquireList'));
// 包机详情
const InquireDetails = React.lazy(() => import('../pages/inquire/InquiryDetails'));
// 个人信息
const PersonalInformation = React.lazy(() => import('../pages/profile/PersonalInformation'));
// 修改密码
const ChangePassword = React.lazy(() => import('../pages/profile/ChangePassword'));
// 乘客
const Passenger = React.lazy(() => import('../pages/profile/Passenger'));
// 支付成功
const PaySuccessful = React.lazy(() => import('../pages/state/PaySuccessful'));
// 支付失败
const PayFail = React.lazy(() => import('../pages/state/PayFail'));
// 优惠卷
const Coupons = React.lazy(() => import('../pages/profile/Coupons'));
// 隐私政策
const PrivacyPolicy = React.lazy(() => import('../pages/commen/PrivacyPolicy'));
// 使用条款
const TermOfUse = React.lazy(() => import('../pages/commen/TermOfUse'));
// 使用条款
const AffiliateAgreement = React.lazy(() => import('../pages/commen/AffiliateAgreement'));
// 关于我们
const AboutUs = React.lazy(() => import('../pages/commen/AboutUs'));
// 联系我们
const ContactUs = React.lazy(() => import('../pages/commen/ContactUs'));
// 与我们合作
const PartnerWithUs = React.lazy(() => import('../pages/commen/PartnerWithUs'));
// Cookies声明
const CookiesPolicy = React.lazy(() => import('../pages/commen/CookiesPolicy'));
// 会员卡页
const Membership = React.lazy(() => import('../pages/membership/Membership'));
// 会员权益页
const MembershipPolicy = React.lazy(() => import('../pages/membership/MembershipPolicy'));
// 个人中心会员卡页
const MembershipCards = React.lazy(() => import('../pages/membership/MembershipCards'));
// 个人中心会员卡页
const AddCardInformation = React.lazy(() => import('../pages/membership/AddCardInformation'));
// 退订会员提交成功
const MemberSuccessful = React.lazy(() => import('../pages/membership/MemberSuccessful'));
// 退订会员提交失败
const MemberFail = React.lazy(() => import('../pages/membership/MemberFail'));
// 退订会员提交失败
const MemberLoading = React.lazy(() => import('../pages/membership/MemberLoading'));
// 圣诞活动支付等待页面
const GiftPayLoading = React.lazy(() => import('../pages/events/christmas/GiftPayLoading'));
// 会员卡订阅支付
const MemberCardPay = React.lazy(() => import('../pages/membership/MemberCardPay'));

const Christmas = React.lazy(() => import('../pages/events/christmas'));

const Checkout = React.lazy(() => import('../pages/events/christmas/Checkout'));
const SendInfo = React.lazy(() => import('../pages/events/christmas/SendInfo'));

// 宠物航班
const PetFriendlyFlight = React.lazy(() => import('../pages/PetFriendlyFlight'));
// 宠物航班详情
const PetFlightDetail = React.lazy(() => import('../pages/PetFriendlyFlight/PetFlightDetail'));
// 宠物航班预定详情
const BookingDetails = React.lazy(() => import('../pages/PetFriendlyFlight/PetFlightDetail/BookingDetails'));
// 宠物航班Success
const PetFlightSuccess = React.lazy(() => import('../pages/PetFriendlyFlight/PetFlightSuccess'));
// 宠物航班Fail
const PetFlightFail = React.lazy(() => import('../pages/PetFriendlyFlight/PetFlightFail'));

// 不登录状态下
export const mainRoutes = [
  {
    // 首页
    path: '/',
    title: 'home',
    component: Home,
  },
  {
    // 活动
    path: '/events/christmas',
    title: 'Christmas',
    component: Christmas,
  },
  {
    // 活动
    path: '/events/christmas/checkout',
    title: 'Christmas Checkout',
    component: Checkout,
  },
  {
    // 活动
    path: '/events/christmas/checkoutSuccess',
    title: 'Christmas SendInfo',
    component: SendInfo,
  },
  {
    // 支付等待
    path: '/events/christmas/loading',
    title: 'Christmas Loading',
    component: GiftPayLoading,
  },

  {
    // 经销商
    path: '/affiliatePartner',
    title: 'affiliatePartner',
    component: AffiliatePartner,
  },

  {
    // 飞机销售
    path: '/jetSale',
    title: 'jetSale',
    component: JetSale,
  },
  {
    // 飞机销售类别
    path: '/jetSale/:category',
    title: 'jetSale',
    component: JetSaleDetails,
  },

  {
    // 获取报价页
    path: '/charterQuote',
    title: 'charterQuote',
    component: CharterQuote,
  },
  {
    // blog页
    path: '/blog',
    title: 'blog',
    component: Blog,
  },
  {
    // blog详情页
    path: '/Blog/:id',
    title: 'blogDetails',
    component: BlogDetails,
  },
  {
    // blog分类页
    path: '/categories/:name',
    title: 'blogCategories',
    component: Blog,
  },
  {
    //定期航班
    path: '/searchFlights',
    title: 'searchFlights',
    component: SearchFlights,
  },
  {
    //定期航班详情页
    path: '/searchDetails/:type/:number/:cityNme/:date',
    title: 'searchDetails',
    component: SearchDetails,
  },
  {
    // 空腿
    path: '/emptyLeg',
    title: 'emptyLeg',
    component: EmptyLeg,
  },
  {
    // 空腿
    path: '/emptyLegDetails/:id',
    title: 'emptyLegDetails',
    component: EmptyLegDetails,
  },
  {
    // 会员卡页
    path: '/membership',
    title: 'membership',
    component: Membership,
  },
  {
    //会员权益页
    path: '/membershipPolicy',
    title: 'membershipPolicy',
    component: MembershipPolicy,
  },
  {
    //填写会员信息页
    path: '/addCardInformation',
    title: 'addCardInformation',
    component: AddCardInformation,
  },
  {
    //会员订阅支付
    path: '/memberCardPay',
    title: 'memberCardPay',
    component: MemberCardPay,
  },
  {
    //会员加载页
    path: '/memberLoading',
    title: 'memberLoading',
    component: MemberLoading,
  },
  {
    // 隐私政策
    path: '/privacyPolicy',
    title: 'PrivacyPolicy',
    component: PrivacyPolicy,
  },
  {
    // 使用条款
    path: '/termOfUse',
    title: 'TermOfUse',
    component: TermOfUse,
  },
  {
    // 附属协议
    path: '/affiliateAgreement',
    title: 'AffiliateAgreement',
    component: AffiliateAgreement,
  },
  {
    // 关于我们
    path: '/aboutUs',
    title: 'AboutUs',
    component: AboutUs,
  },
  {
    // 联系我们
    path: '/contactUs',
    title: 'ContactUs',
    component: ContactUs,
  },
  {
    // 与我们合作
    path: '/partnerWithUs',
    title: 'PartnerWithUs',
    component: PartnerWithUs,
  },
  {
    // cookies政策
    path: '/cookies',
    title: 'Cookies',
    component: CookiesPolicy,
  },
  {
    // 退订会员提交成功
    path: '/memberSuccessful',
    title: 'memberSuccessful',
    component: MemberSuccessful,
  },
  {
    // 退订会员提交失败
    path: '/memberFail',
    title: 'memberFail',
    component: MemberFail,
  },
  {
    //宠物航班
    path: '/petFriendlyFlight',
    title: 'PetFriendlyFlight',
    component: PetFriendlyFlight,
  },
  {
    //宠物航班详情页
    path: '/petFriendlyFlight/detail/:Id/:region',
    title: 'PetFlightDetail',
    component: PetFlightDetail,
  },
  {
    //宠物航班详情页
    path: '/petFriendlyFlight/detail/BookingDetails',
    title: 'BookingDetails',
    component: BookingDetails,
  },
  {
    //宠物航班Success
    path: '/petFriendlyFlight/success',
    title: 'PetFlightSuccess',
    component: PetFlightSuccess,
  },
  {
    //宠物航班Fail
    path: '/petFriendlyFlight/fail',
    title: 'PetFlightFail',
    component: PetFlightFail,
  },
];
// 登录状态
export const tokenRouter = [
  {
    // 支付详情
    path: '/payDetails',
    title: 'payDetails',
    component: PayDetails,
  },
  {
    // 定期航班支付详情
    path: '/searchPayDetails',
    title: 'searchPayDetails',
    component: SearchPayDetails,
  },
  {
    // 订单详情
    path: '/flightsDetails/:id',
    title: 'flightsDetails',
    component: FlightsDetails,
  },
  {
    // 定期航班订单详情
    path: '/scheduledDetails/:id',
    title: 'scheduledDetails',
    component: ScheduledDetails,
  },
  {
    // 宠物航班详情
    path: '/petDetails',
    title: 'petDetails',
    component: PetDetails,
  },
  {
    // 乘客详情
    path: '/flightsPassenger/:data',
    title: 'flightsPassenger',
    component: FlightsPassenger,
  },
  {
    // 定期航班乘客详情
    path: '/searchFlightsPassengers/:id',
    title: 'searchFlightsPassengers',
    component: SearchFlightsPassengers,
  },

  {
    // 包机详情
    path: '/inquireDetails/:id',
    title: 'InquireDetails',
    component: InquireDetails,
  },
  {
    // 支付成功
    path: '/paySuccessful/:data',
    title: 'paySuccessful',
    component: PaySuccessful,
  },
  {
    // 支付失败
    path: '/payFail',
    title: 'payFail',
    component: PayFail,
  },
];
// 登录个人中心二级
export const userRouter = [
  {
    // 订单列表
    path: '/flightsList',
    title: 'FlightsList',
    component: FlightsList,
  },
  {
    // 包机列表
    path: '/inquireList',
    title: 'InquireList',
    component: InquireList,
  },
  {
    // 个人信息
    path: '/personalInformation/:type',
    title: 'personalInformation',
    component: PersonalInformation,
  },
  {
    // 修改密码
    path: '/changePassword',
    title: 'changePassword',
    component: ChangePassword,
  },
  {
    // 乘客
    path: '/passenger/:data',
    title: 'passenger',
    component: Passenger,
  },
  {
    // 优惠卷
    path: '/coupons',
    title: 'coupons',
    component: Coupons,
  },
  {
    // 会员卡
    path: '/membershipCards',
    title: 'membershipCards',
    component: MembershipCards,
  },
];
