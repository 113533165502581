/*
  *优惠卷弹窗
  bill_yang 2021.10
*/
import React, { Component } from 'react';
import '../../assets/style/itemStyle/coupons.scss';
import { openLoginPopup } from '../../untils/auth';
import Signup from '../sign/Signup';

export default class Coupons extends Component {
  state = {
    activity: false,
  };
  // 去登录
  openLoginPopupHandle = () => {
    openLoginPopup('open');
    let body = document.body;
    body.style.overflow = 'hidden';
  };

  // 关闭
  closeHandle = () => {
    this.props.onCancel();
  };
  componentDidMount() {
    let { couponDetail } = this.props;
    if (Object.keys(couponDetail).length > 0) {
      this.setState({
        activity: true,
      });
    }
  }

  render() {
    let { activity } = this.state;
    let { couponDetail } = this.props;
    return (
      <div className="activity-popup">
        <div className="activity-content">
          <div className="activity-top">
            <img
              src={require('../../assets/imgs/webp/activity-bg.webp').default}
              alt="plan img"
              className="coupons-img"
            />
            <div className="activity-text">
              <div className={`title ${activity ? '' : 'active'}`}>
                {activity ? couponDetail.Name : 'Sign Up Airacer Now'}
              </div>
              <div className={`text ${activity ? '' : 'active'}`}>
                {activity
                  ? couponDetail.MainDescription
                  : 'Be the first to hear about special discounts, flights information and more !'}
              </div>
            </div>

            <button className="activity-close bg-image" onClick={this.closeHandle}></button>
          </div>

          <div className="coupons-popup">
            <div className="coupons-wrapper">
              <Signup
                type="coupons"
                openLoginPopupHandle={this.openLoginPopupHandle}
                login={() => {
                  this.props.couponsPopupFun();
                }}
                gainCoupon={this.props.gainCoupon}
                facebookLogin={this.props.facebookLogin}
                onCancel={this.props.onCancel}
                couponsBoundFacebook={this.props.couponsBoundFacebook}
                couponShow={true}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
