import React, { useEffect, useState } from "react";
import { PasswordContainer, PasswordTitle, PasswordBox, Item } from './VerifyPassword.style'
import passwordCorrectIcon from '../../assets/imgs/svg/suo/green-suo.svg'
import passwordErrorIcon from '../../assets/imgs/svg/suo/red-suo.svg'
import correctIcon from '../../assets/imgs/svg/suo/green-dui.svg'
import errorIcon from '../../assets/imgs/svg/suo/red-x.svg'

// 密码匹配条件项
const passwordText = [
  'Include lowercase and uppercase letters.',
  'Include at least one number and symbol.',
  'Be at least 8 characters long.',
];

export default function VerifyPassword ({ passwordValue, positionTop }) {

  const matchPasswordHandle = (step) => {
    let password;
    switch (step) {
      case 0:
        // 至少包含一个大写字母，一个小写字母
        password = /^(?=.*[a-z])(?=.*[A-Z])[^]{2,}/;
        break;
      case 1:
        // 至少包含一个数字，一个特殊符号
        password = /^(?=.*\d)(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&]{2,}/;
        break;
      case 2:
        // 至少8位
        password = /^[^]{8,}/;
        break;
      default:
        password = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&]{8,}/; 
    }
    if(passwordValue) return password.test(passwordValue) ? true : false;
  }

  useEffect(() => { 
    // console.log(passwordValue)
  }, [passwordValue])

  return (
    <PasswordContainer className={`verify-password ${matchPasswordHandle('all') ? 'all' : ''}`} positionTop={positionTop}>
      <PasswordTitle className="title">
        <img src={matchPasswordHandle('all') ? passwordCorrectIcon : passwordErrorIcon} alt="password icon" />
        <span>Your password needs to:</span>
      </PasswordTitle>
      <PasswordBox>
        {
          passwordText && passwordText.map((item, index) => {
            return (
              <Item key={item} className={`${matchPasswordHandle(index) ? 'active' : ''}`}>
                <img src={matchPasswordHandle(index) ? correctIcon : errorIcon} alt="error icon" />
                <span>{item}</span>
              </Item>
            )
          })
        }
      </PasswordBox>
    </PasswordContainer>
  );
}

