import styled from 'styled-components';

export function vw (pxValue) {
  return pxValue * 1 + 'rem'
}

export const SelectMenuContainer = styled.div`
  display: inline-block;
  position: relative;
  width: ${props => (props.width ? vw(props.width) : '100%')};
  background-color: ${props => (props.bg ? vw(props.bg) : 'transparent')};
  font-weight: 400;
  font-size: ${vw(18)};
  line-height: ${vw(22)};
  color: #000000;
  box-sizing: border-box;
  cursor:pointer;
  margin-top: ${props => (props.marginTop ? vw(props.marginTop) : 0)};
  margin-bottom: ${props => (props.marginBottom ? vw(props.marginBottom) : 0)};
  z-index:100;

  .choose_name {
    height: ${props => (props.height ? vw(props.height) : vw(56))};
    border: ${vw(1.6)} solid rgba(0,0,0,0.2);
    border-radius: ${vw(8)};
    padding: ${vw(16)} ${vw(24)};
    color: rgba(13, 17, 35, 0.48);
    display: flex;
    justify-content: space-between;
    align-items: center;

    &.active {
      color: #07080A;
    }
    &:hover{
      border-color:#07080A;
    }

    img {
      width: ${vw(24)};
      height: ${vw(24)};
      // transition: all 0.24s ease-in-out;

      &.open_arrow {
        transform: rotate(180deg);
      }
    }
  }
`;

export const SelectBox = styled.ul`
  position: absolute;
  top: ${vw(60)};
  left: 0;
  background: #FFFFFF;
  box-shadow: 0 ${vw(2)} ${vw(30)} rgba(77, 100, 126, 0.25);
  border-radius: ${vw(8)};
  width: 100%;
  height: ${props => (props.height ? vw(props.height) : vw(240))};;
  padding-bottom: ${vw(8)};
  overflow-y: auto; 
  scrollbar-width: ${vw(0)};
  -ms-overflow-style: none;
  display: none;
  text-align: left;

  // ::-webkit-scrollbar {
  //   display: none;
  // }
`;

export const OptionList = styled.li`
  position: relative;
  width: 100%;
  padding: ${vw(8)} ${vw(24)};
  margin-top:${vw(8)};
  text-transform: capitalize;
  &:hover{
    background: ${props => props.hoverBackground || '#DFE7F5'};
  }
  &.active {
    color: #0063DB;

    &::before {
      content: '';
      display: block;
      width: ${vw(4)};
      height: ${vw(24)};
      background: #0063DB;
      position: absolute;
      left: 0;
    }
  }
`;

export const StyledImg = styled.img`
  width: 20px;
  height: 5px;
`;

export const OptionLabel = styled.span`
  margin-left: ${({childDataSource, label}) => childDataSource.includes(label) ? '24px' : '0'};
`;

export const OptionContainer = styled.div`
  display: flex;
  align-items: center;
`;