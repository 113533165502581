import React, { useEffect, useState } from "react";
import {
  SelectMenuContainer,
  SelectBox,
  OptionList,
  StyledImg,
  OptionLabel, OptionContainer
} from './selectMenu.style'
import ArrowIcon from '../../assets/imgs/svg/select-sanjiao.svg'

const originDataSource = [
  'Search Engine (Google, Bing, etc.)',
  'Social Media',
  'Referral',
  'Blog or Publication',
  'Newsletter',
  'Internet Ads',
  'Other',
]

const childDataSource = [
  'Facebook',
  'Instagram',
  'Twitter',
  'Linkedin',
  'TikTok',
]

export default function SelectMenu ({
  width,
  getSourceValue,
  marginTop,
  marginBottom,
  className,
  sourceValue,
}) {
  const [currentValue, setCurrentValue] = useState('');
  const [dataSource, setDataSource] = useState(originDataSource);
  const [menuShow, setMenuShow] = useState(false);
  const [isSubMenuVisible, setIsSubMenuVisible] = useState(false);

  const toggleSubMenu = () => {
    setDataSource(isSubMenuVisible ? originDataSource : [...originDataSource.slice(0, 2), ...childDataSource, ...originDataSource.slice(2)])
    setIsSubMenuVisible(!isSubMenuVisible);
  };

  // 打开菜单选项
  const openDropDownList = () => {
    setMenuShow(!menuShow);
  }

  // 选择菜单项
  const chooseItem = val => {
    setCurrentValue(val);
    setMenuShow(false);
    getSourceValue(val);
  }
  
  const closeMenuOptionHandle = e => {
    const eventPath = e.path || (e.composedPath && e.composedPath()) || '';
    if (eventPath) {
      const element = eventPath.find(dom => dom.className && dom.className.includes('dropDownMenu'));
      if (!element) setMenuShow(false);
    }
  }

  useEffect(() => { 
    setCurrentValue(sourceValue);
    document.addEventListener('click', closeMenuOptionHandle);
    return () => {
      document.removeEventListener('click', closeMenuOptionHandle);
    }
  }, [sourceValue])

  return (
    <SelectMenuContainer className={`dropDownMenu ${className ? className : ''}`} width={width} marginTop={marginTop} marginBottom={marginBottom}>
      <div className={`choose_name ${currentValue ? 'active' : ''}`} onClick={openDropDownList}>
        <span>{ currentValue || 'How did you hear about us?'}</span>
        <img src={ArrowIcon} alt="down icon" className={`${menuShow ? 'open_arrow' : ''}`} />
      </div>
      {dataSource &&
        <SelectBox style={{ 'display': `${menuShow ? 'block' : 'none'}` }}>
          {dataSource.map((item, index) =>
            <OptionList key={item} className={`${index === dataSource.indexOf(currentValue) ? 'active' : ''}`} onClick={()=>{index === 1 ? toggleSubMenu() : chooseItem(item)}}>
              <OptionContainer>
                <OptionLabel childDataSource={childDataSource} label={item}>{item}</OptionLabel>
                {item === 'Social Media' && (
                    <>
                      {isSubMenuVisible ? <StyledImg src={require('assets/imgs/svg/up-arrow.svg').default} alt="icon"/> : <StyledImg src={require('assets/imgs/svg/down-arrow.svg').default} alt="icon"/>}
                    </>
                )}
              </OptionContainer>
            </OptionList>
          )}
        </SelectBox>
      }
    </SelectMenuContainer>
  );
}

