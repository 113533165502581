import { ChevronDown } from 'lucide-react';
import React, { Component } from 'react';
import { clearSessionStorage } from '../../untils/auth';

export default class Navbar extends Component {
  //clear data
  initPage = (e, url) => {
    e.preventDefault();
    clearSessionStorage('from');
    clearSessionStorage('to');
    clearSessionStorage('flyLocationTo');
    clearSessionStorage('flyLocationForm');
    clearSessionStorage('userFormData');
    clearSessionStorage('emptyLegsStartDate');
    clearSessionStorage('emptyLegsEndDate');
    this.props.history.push(`/${url}`);
  };

  render() {
    const { isScroll } = this.props;
    return (
      <ul className={`header-nav ${this.props.name}`}>
        <li className="header-nav-parent">
          <span>
            <a>Airacer</a>
            <ChevronDown size={20} color={isScroll ? 'black' : 'white'} />
          </span>
          <ul className="header-nav-children about-airacer">
            <li>
              <a href="/jetSale">Jet Sales</a>
            </li>
          </ul>
        </li>
        <li className="header-nav-parent">
          <span>
            <a
              href="javascripts:;"
              onClick={(e) => {
                this.initPage(e, '');
              }}
            >
              Search Flights
            </a>
            <ChevronDown size={20} color={isScroll ? 'black' : 'white'} />
          </span>
          <ul className="header-nav-children about-airacer">
            <li>
              <a
                href="javascripts:;"
                onClick={(e) => {
                  this.initPage(e, 'charterQuote');
                }}
              >
                Private Charters
              </a>
            </li>
            <li>
              <a
                href="javascripts:;"
                onClick={(e) => {
                  this.initPage(e, 'emptyLeg');
                }}
              >
                Empty Legs
              </a>
            </li>
            <li>
              <a
                href="javascripts:;"
                onClick={(e) => {
                  this.initPage(e, 'searchFlights');
                }}
              >
                Scheduled Flights
              </a>
            </li>
            <li>
              <a
                href="javascripts:;"
                onClick={(e) => {
                  this.initPage(e, 'petFriendlyFlight');
                }}
              >
                Pet Friendly Flight
              </a>
            </li>
          </ul>
        </li>
        <li>
          <a
            href="javascripts:;"
            onClick={(e) => {
              this.initPage(e, 'membership');
            }}
          >
            Memberships
          </a>
        </li>
        <li>
          <a href="/blog">Blog</a>
        </li>
        <li className="header-nav-parent">
          <span>
            {/* About Airacer */}
            <a href="/aboutUs">About Us</a>
            <ChevronDown size={20} color={isScroll ? 'black' : 'white'} />
          </span>
          <ul className="header-nav-children about-airacer">
            <li>
              <a href="/contactUs">Contact Us</a>
            </li>
            <li>
              <a href="/partnerWithUs">Partner With Us</a>
            </li>
            <li>
              <a href="/affiliatePartner">Become an Affiliate Partner</a>
            </li>
          </ul>
        </li>
      </ul>
    );
  }
}
