import styled from 'styled-components';
// import { vw } from '../../theme/style';
export function vw (pxValue) {
    return pxValue * 1 + 'rem'
  }


export const PasswordContainer = styled.div`
  position: absolute;
  top: ${props => (props.positionTop ? vw(props.positionTop) : vw(85))};
  left: 0;
  width: 100%;
  background: #FFFFFF;
  box-shadow: 0px 2.17969px 30.5156px rgba(77, 100, 126, 0.25);
  border-radius: ${vw(8)};
  border: ${vw(1)} solid #FF4E4B;
  padding: ${vw(8)} ${vw(16)};
  color: #07080A;
  z-index: 199;

  img {
    width: ${vw(24)};
    height: ${vw(24)};
  }

  span {
    font-weight: 400;
    font-size: ${vw(12)};
    line-height: ${vw(15)};
    margin-left: ${vw(8)};
  }

  &.all {
    border-color: #2DCA73;
    color: #2DCA73;

    .title {
      color: #2DCA73;
    }
  }
`;

export const PasswordTitle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${vw(4)};
  color: #FF4E4B;

  >span {
    font-size: ${vw(14)};
  }
`;

export const PasswordBox = styled.ul`
  padding: 0 ${vw(24)};
`;

export const Item = styled.li`
  display: flex;
  align-items: center;
  margin: 0 !important;

  &.active {
    color: #2DCA73;
  }
`;