/*
  *注册组件
  bill_yang 2021.09
*/
import React, { Component } from 'react';
import { Input, Button, message, Form } from 'antd';
import {
  regularCheck,
  setLocalStorage,
  getLocalStorage,
} from '../../untils/auth';
import { wxBound } from '../../services/index';
import md5 from 'js-md5';
import store from '../../redux/store';

export default class BoundWx extends Component {
  // 提交
  onFinish = e => {
    if (e.password !== e.passwordOnce) {
      return message.error('The password is not the same!');
    }
    // 传参
    let params = {
      account: e.account,
      unionId: this.props.id,
      password: md5(e.passwordOnce),
    };
    wxBound(params).then(res => {
      if (res.code) {
        let user = {
          name: res.data.name,
          surName: res.data.nickname,
          country: res.data.country,
          photo: res.data.photo,
        };
        // 弹出领卷成功
        if (res.data.newUser !== undefined) {
          this.props.gainCoupon();
        }
        // 判断是否google 登录
        if (res.data.type !== undefined) {
          setLocalStorage('loginType', true);
        }
        store.dispatch({
          type: 'token',
          data: res.data.accessToken,
        });
        setLocalStorage('user', JSON.stringify(user));
        setLocalStorage('userId', res.data.userid);
        setLocalStorage('time', res.data.accessExpire + '000');
        setLocalStorage('loginType', true);
        message.success('successful!');
        this.props.changeLogin();
        // 详情页
        if (getLocalStorage('details') !== null) {
          return this.props.history.push('/payDetails');
        }
        this.props.history.push('/');
      } else {
        message.error(res.msg);
      }
    });
  };
  render() {
    return (
      <div className="public-form signin wx">
        <p className="form-title">
          Please provide your email adress & phone number
        </p>
        <Form
          name="basic"
          initialValues={{
            remember: true,
          }}
          onFinish={this.onFinish}
          autoComplete="off"
        >
          <Form.Item
            name="account"
            rules={[
              {
                required: true,
                message: '',
              },
              {
                validator: (rule, value) => {
                  if (regularCheck('email', value)) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject(new Error('Please enter a valid email address'));
                  }
                },
              },
            ]}
          >
            <Input
              prefix={
                <img
                  src={require('../../assets/imgs/svg/icon_email.svg').default}
                  alt="email icon"
                />
              }
              placeholder="Email Address"
              allowClear
            />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: '',
              },
              {
                validator: (rule, value) => {
                  if (regularCheck('password', value)) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject(
                      new Error(
                        'Password must be at least 8 characters. Must contain at least one uppercase, one lowercase, one number, and one special character.'
                      )
                    );
                  }
                },
              },
            ]}
          >
            <Input.Password
              prefix={
                <img
                  src={
                    require('../../assets/imgs/svg/icon_password.svg').default
                  }
                  alt="password icon"
                />
              }
              placeholder="Password"
            />
          </Form.Item>
          <Form.Item
            name="passwordOnce"
            rules={[
              {
                required: true,
                message: '',
              },
            ]}
          >
            <Input.Password
              prefix={
                <img
                  src={
                    require('../../assets/imgs/svg/icon_password.svg').default
                  }
                  alt="password icon"
                />
              }
              placeholder="Password"
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
}
